import React from 'react'
import styled from 'styled-components'
import { useState } from 'react'

const BubbleWrapper = styled.div`
    width: fit-content;
    list-style: none;
    margin: -2rem 1rem 2rem 1rem;

    &.sent {
        margin-left: auto;
    }

    &.received {
        margin-right: auto;
    }
`;

const Bubble = styled.li`
    --sentColor: #0b93f6;
    --receiveColor: #e5e5ea;

    font-size: 1.4rem;
    max-width: 450px;

    z-index: 1;
    position: relative;
    
    width: fit-content;
    margin-bottom: 15px;
    padding: 1rem 1.5rem;
    word-wrap: break-word;
    border-radius: 50px;
      
    &:before {
        width: 20px;
    }
    
    &:after {
        width: 10px;
        background-color: white;
    }
    
    &:before,
    &:after {
        position: absolute;
        bottom: 0;
        height: 25px;
        content: '';
    }

    &.noTail {
        margin-bottom: 2px;
      
        &:before,
        &:after {
          opacity: 0;
        }
    }
      
    &.sent {
        margin-left: auto;
        color: white;
        background: var(--sentColor);
      
        &:before {
          right: -7px;
          background-color: var(--sentColor);
          border-bottom-left-radius: 16px 14px;
        }
      
        &:after {
          right: -10px;
          border-bottom-left-radius: 10px;
        }
    }
      
    &.received {
        align-self: flex-start;
        color: black;
        background: var(--receiveColor);
      
        &:before {
          left: -7px;
          background-color: var(--receiveColor);
          border-bottom-right-radius: 16px 14px;
        }
      
        &:after {
          left: -10px;
          border-bottom-right-radius: 10px;
        }
    }
`

const Icon = styled.div`
    position: relative;
    width: fit-content;
    margin-bottom: -1.7rem;
    z-index: 99;

    &.received {
        margin-left: auto;
        margin-right: -1.4rem;
    }

    &.sent {
        margin-right: auto;
        margin-left: -1.8rem;
    }
`

const TextBubble = ({text, status, reaction, isLast}) => {

    const width = "2.5rem";

    const reactions = {
        "heartTitle" : <HeartIcon width={width} title={true}/>,
        "heart": <HeartIcon width={width} title={false}/>,
        "haha" : <HahaIcon width={width}/>,
        "like" : <ThumbsIcon width={width}/>
    }

    return (
        <BubbleWrapper className={status}>
            {reactions[reaction]}
            <Bubble className={status + (isLast ? "" : " noTail")}>{text}</Bubble>
        </BubbleWrapper>
    )
}

export default TextBubble;

const Heart = ({color, width}) => {
    return (
      <Icon className="received" width={width} viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill={color} d="M2.66262 0.493696C2.4067 0.649681 2.05462 0.919998 1.88033 1.09429C1.70589 1.26873 1.45039 1.58363 1.31244 1.79427C1.17434 2.0049 1.00005 2.44196 0.924991 2.76553C0.849934 3.0891 0.788574 3.57397 0.788574 3.84303C0.788574 4.11209 0.848676 4.58382 0.922195 4.89131C0.995575 5.19881 1.25401 5.84036 1.49624 6.31712C1.78556 6.88612 2.35722 7.60692 3.16119 8.4162C3.83474 9.09395 4.60586 9.80259 4.87492 9.99086C5.14398 10.1791 5.44966 10.4163 5.55435 10.5179C5.6589 10.6195 6.04998 10.8576 6.42316 11.0467C6.79649 11.2359 7.18869 11.3907 7.29464 11.3907C7.40058 11.3907 7.89006 11.1311 8.38248 10.8138C8.87489 10.4966 9.30916 10.1946 9.3476 10.1432C9.38603 10.0916 9.63762 9.89595 9.90668 9.70824C10.1757 9.52067 10.7174 9.04643 11.1102 8.65423C11.5031 8.26217 12.1338 7.47512 12.5116 6.90555C12.8894 6.33585 13.2862 5.61812 13.3934 5.31063C13.5006 5.00313 13.5856 4.36172 13.5821 3.88524C13.5787 3.4089 13.4901 2.72877 13.3854 2.3739C13.2806 2.01902 13.0041 1.52045 12.7709 1.26593C12.5377 1.01127 12.1281 0.669528 11.8607 0.506555C11.4791 0.273976 11.1483 0.209961 10.326 0.209961C9.50693 0.209961 9.17232 0.274256 8.79592 0.50376C8.53091 0.665335 8.13634 0.987787 7.91899 1.22037C7.70179 1.45294 7.4625 1.74521 7.38744 1.8696C7.26543 2.07213 7.22531 2.06486 7.00643 1.80084C6.87197 1.63856 6.63604 1.34742 6.48229 1.15397C6.32854 0.960532 5.98261 0.668969 5.71355 0.506136C5.33057 0.274256 4.99666 0.209961 4.17607 0.209961C3.36875 0.209961 3.02086 0.275234 2.66262 0.493696Z"/>
      </Icon>
    )  
}

const HeartIcon = ({width, title}) => {
    return (
        <Icon className="received">
            <svg width={width} viewBox="0 0 118 116" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill={title ? "#E9E9EA" : "#4C90F7"} stroke="white" d="M102.002 51.4766C102.002 60.2268 99.7764 68.4573 95.8606 75.6329C100.695 78.123 104.002 83.1636 104.002 88.9766C104.002 97.2608 97.2862 103.977 89.002 103.977C83.189 103.977 78.1484 100.67 75.6583 95.8352C68.4827 99.7511 60.2522 101.977 51.502 101.977C23.6116 101.977 1.00195 79.3669 1.00195 51.4766C1.00195 23.5862 23.6116 0.976562 51.502 0.976562C79.3923 0.976562 102.002 23.5862 102.002 51.4766ZM109.002 114.977C104.584 114.977 101.002 111.395 101.002 106.977C101.002 102.558 104.584 98.9766 109.002 98.9766C113.42 98.9766 117.002 102.558 117.002 106.977C117.002 111.395 113.42 114.977 109.002 114.977Z"/>
            <path fill={title ? "#F85D96" : "white"} fill-rule="evenodd" clip-rule="evenodd" d="M34.206 36.4916C33.2905 37.0496 32.031 38.0166 31.4075 38.6401C30.7835 39.2641 29.8695 40.3906 29.376 41.1441C28.882 41.8976 28.2585 43.4611 27.99 44.6186C27.7215 45.7761 27.502 47.5106 27.502 48.4731C27.502 49.4356 27.717 51.1231 27.98 52.2231C28.2425 53.3231 29.167 55.6181 30.0335 57.3236C31.0685 59.3591 33.1135 61.9376 35.9895 64.8326C38.399 67.2571 41.1575 69.7921 42.12 70.4656C43.0825 71.1391 44.176 71.9876 44.5505 72.3511C44.9245 72.7146 46.3235 73.5661 47.6585 74.2426C48.994 74.9196 50.397 75.4731 50.776 75.4731C51.155 75.4731 52.906 74.5446 54.6675 73.4096C56.429 72.2746 57.9825 71.1946 58.12 71.0106C58.2575 70.8261 59.1575 70.1261 60.12 69.4546C61.0825 68.7836 63.02 67.0871 64.4255 65.6841C65.831 64.2816 68.087 61.4661 69.4385 59.4286C70.79 57.3906 72.2095 54.8231 72.593 53.7231C72.9765 52.6231 73.2805 50.3286 73.268 48.6241C73.256 46.9201 72.939 44.4871 72.5645 43.2176C72.1895 41.9481 71.2005 40.1646 70.366 39.2541C69.532 38.3431 68.0665 37.1206 67.11 36.5376C65.745 35.7056 64.5615 35.4766 61.62 35.4766C58.69 35.4766 57.493 35.7066 56.1465 36.5276C55.1985 37.1056 53.787 38.2591 53.0095 39.0911C52.2325 39.9231 51.3765 40.9686 51.108 41.4136C50.6715 42.1381 50.528 42.1121 49.745 41.1676C49.264 40.5871 48.42 39.5456 47.87 38.8536C47.32 38.1616 46.0825 37.1186 45.12 36.5361C43.75 35.7066 42.5555 35.4766 39.62 35.4766C36.732 35.4766 35.4875 35.7101 34.206 36.4916Z"/>
            </svg>
        </Icon>
    )  
}

const ThumbsIcon = ({width}) => {
    return (
        <Icon className="sent">
            <svg width={width} viewBox="0 0 118 116" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill="#E9E9EA" stroke="white" d="M15.9746 51.7C15.9746 60.4502 18.2001 68.6807 22.116 75.8563C17.2811 78.3464 13.9746 83.387 13.9746 89.2C13.9746 97.4842 20.6903 104.2 28.9746 104.2C34.7875 104.2 39.8282 100.893 42.3183 96.0586C49.4938 99.9744 57.7243 102.2 66.4746 102.2C94.365 102.2 116.975 79.5903 116.975 51.7C116.975 23.8096 94.365 1.19995 66.4746 1.19995C38.5842 1.19995 15.9746 23.8096 15.9746 51.7ZM8.97461 115.2C13.3929 115.2 16.9746 111.618 16.9746 107.2C16.9746 102.782 13.3929 99.2 8.97461 99.2C4.55633 99.2 0.974609 102.782 0.974609 107.2C0.974609 111.618 4.55633 115.2 8.97461 115.2Z"/>
                <path fill="#808080" d="M66.2842 32.039C66.9977 30.615 67.9277 29.0565 68.3517 28.575C68.7757 28.0935 69.4362 27.7 69.8197 27.7C70.2032 27.7 71.1492 28.027 71.9216 28.4265C72.6941 28.826 73.6002 29.814 73.9352 30.6225C74.3207 31.553 74.4617 33.6245 74.3197 36.271C74.1967 38.5695 73.8521 41.35 73.5541 42.45C73.2556 43.55 72.8737 45.0125 72.7047 45.7L72.3976 46.95L79.9666 46.7785C86.7346 46.625 87.6422 46.7035 88.5427 47.5185C89.3402 48.24 89.4827 48.796 89.2276 50.19C89.0507 51.158 88.7252 53.8625 88.5052 56.2C88.2847 58.5375 87.9272 60.985 87.7107 61.639C87.4942 62.293 86.5502 64.093 85.6132 65.639C84.6762 67.185 83.7927 69.0125 83.6497 69.7C83.5067 70.3875 83.1262 71.3045 82.8037 71.7374C82.4812 72.1704 81.5712 72.946 80.7812 73.46C79.9912 73.974 78.1912 74.6075 76.7812 74.867C75.3712 75.1264 71.8547 75.334 68.9672 75.3275C66.0797 75.3215 62.3672 75.1154 60.7172 74.8699C59.0672 74.624 57.0162 74.005 56.1602 73.4935C55.3037 72.9825 54.1787 71.8634 53.6602 71.007C53.0372 69.979 52.5887 67.9215 52.3397 64.95C52.1317 62.475 51.9442 59.8875 51.9232 59.2C51.9017 58.5125 52.0717 57.1549 52.3007 56.1834C52.5297 55.2119 53.3617 53.412 54.1492 52.1835C54.9367 50.955 56.3992 49.3655 57.3992 48.651C58.3992 47.9365 59.9841 46.0665 60.9212 44.496C61.8587 42.925 63.1567 40.0619 63.8062 38.1339C64.4557 36.2054 65.5707 33.4625 66.2842 32.039Z"/>
                <path fill="#808080" d="M41.1732 57.95C41.5532 56.85 42.5812 55.2185 43.4577 54.325C44.6962 53.0625 45.4712 52.7 46.9322 52.7C48.1612 52.7 49.1177 53.0374 49.6947 53.6749C50.3922 54.4454 50.5027 55.101 50.2227 56.7999C50.0277 57.9825 49.8552 60.6375 49.8392 62.7C49.8232 64.7625 50.0602 67.35 50.3662 68.45C50.6722 69.5499 51.0677 71.0509 51.2457 71.7859C51.5202 72.9209 51.3397 73.2404 50.0427 73.9109C49.2037 74.3449 47.9607 74.7 47.2817 74.7C46.4887 74.7 45.2712 73.9209 43.8817 72.5239C42.6257 71.2614 41.4547 69.481 41.0922 68.282C40.7487 67.1455 40.4707 64.806 40.4747 63.083C40.4787 61.36 40.7932 59.05 41.1732 57.95Z"/>
            </svg>
        </Icon>
    )  
}

const HahaIcon = ({width}) => {
    return (
        <Icon className="sent">
            <svg width={width} viewBox="0 0 118 116" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="#E9E9EA" stroke="white" d="M15.502 51.0527C15.502 59.803 17.7275 68.0335 21.6433 75.2091C16.8085 77.6992 13.502 82.7398 13.502 88.5527C13.502 96.837 20.2177 103.553 28.502 103.553C34.3149 103.553 39.3555 100.246 41.8456 95.4114C49.0212 99.3272 57.2517 101.553 66.002 101.553C93.8923 101.553 116.502 78.9431 116.502 51.0527C116.502 23.1624 93.8923 0.552734 66.002 0.552734C38.1116 0.552734 15.502 23.1624 15.502 51.0527ZM8.50195 114.553C12.9202 114.553 16.502 110.971 16.502 106.553C16.502 102.134 12.9202 98.5527 8.50195 98.5527C4.08368 98.5527 0.501953 102.134 0.501953 106.553C0.501953 110.971 4.08368 114.553 8.50195 114.553Z"/>
            <path fill="#808080" d="M55.1817 30.7567C55.3112 30.1827 55.8882 29.1142 56.4642 28.3817C57.3362 27.2732 57.9027 27.0532 59.8502 27.0667C61.1362 27.0752 62.5432 27.3567 62.9762 27.6917C63.4092 28.0267 64.1727 28.9287 64.6722 29.6962C65.1717 30.4637 65.8137 32.0387 66.0987 33.1962C66.3832 34.3537 66.6167 38.3382 66.6167 42.0507C66.6167 45.7632 66.3772 49.7812 66.0847 50.9797C65.7922 52.1782 65.1112 53.7532 64.5717 54.4797C63.9262 55.3482 63.0202 55.8567 61.9277 55.9637C61.0137 56.0537 59.7707 55.8622 59.1657 55.5387C58.5612 55.2147 57.5877 54.0982 57.0027 53.0572C56.4172 52.0162 55.9387 50.6777 55.9387 50.0827C55.9387 49.4877 55.5882 48.3232 55.1597 47.4947C54.5937 46.4002 54.0817 46.0312 53.2847 46.1447C52.3257 46.2812 52.1477 46.7067 51.8627 49.5507C51.6832 51.3382 51.3737 53.4282 51.1757 54.1947C50.9692 54.9922 50.2097 55.9022 49.4017 56.3197C48.6242 56.7217 47.2247 57.0507 46.2912 57.0507C45.3002 57.0507 44.2742 56.6867 43.8267 56.1757C43.4047 55.6942 42.6567 54.5132 42.1647 53.5507C41.6727 52.5882 41.0477 50.9007 40.7757 49.8007C40.5042 48.7007 40.1932 44.4962 40.0857 40.4577C39.8972 33.4207 39.9412 33.0122 41.1382 30.6507C42.1202 28.7122 42.7127 28.1207 43.9127 27.8807C44.7522 27.7132 46.1252 27.7132 46.9647 27.8807C48.1592 28.1197 48.7562 28.7107 49.7147 30.6017C50.3877 31.9302 50.9387 33.7777 50.9387 34.7067C50.9387 35.7392 51.3132 36.7347 51.9017 37.2672C52.4312 37.7462 53.2747 38.0602 53.7767 37.9647C54.5202 37.8232 54.7122 37.2382 54.8172 34.7957C54.8882 33.1487 55.0522 31.3312 55.1817 30.7567Z"/>
            <path fill="#808080" d="M58.9387 63.0412C58.9387 61.1642 59.2057 60.4197 60.2567 59.3687C61.0367 58.5892 62.1322 58.0507 62.9387 58.0507C63.7312 58.0507 64.8327 58.5807 65.5677 59.3162C66.2637 60.0117 66.9892 61.4182 67.1802 62.4412C67.3712 63.4637 67.6367 66.2972 67.7702 68.7372C67.9287 71.6367 67.7837 73.9512 67.3507 75.4187C66.9867 76.6532 66.1517 78.2007 65.4957 78.8572C64.8392 79.5137 63.9067 80.0507 63.4232 80.0507C62.9392 80.0507 62.0302 79.5172 61.4027 78.8657C60.7757 78.2137 59.8832 76.4697 59.4197 74.9907C58.9322 73.4332 58.2852 72.3007 57.8832 72.3007C57.4327 72.3007 57.0957 73.0912 56.9237 74.5507C56.7777 75.7882 56.2457 77.4942 55.7402 78.3417C55.2352 79.1892 54.3952 80.1497 53.8727 80.4757C53.1242 80.9432 52.6267 80.9152 51.5197 80.3427C50.7477 79.9432 49.8917 79.3207 49.6172 78.9587C49.3432 78.5967 48.8627 77.4007 48.5492 76.3007C48.2362 75.2007 47.8297 73.5132 47.6462 72.5507C47.4632 71.5882 47.3172 69.3382 47.3222 67.5507C47.3272 65.7632 47.4912 63.4712 47.6862 62.4572C47.8812 61.4432 48.4457 60.2467 48.9407 59.7987C49.5277 59.2677 50.5037 59.0392 51.7487 59.1427C53.2732 59.2687 53.8857 59.6422 54.7977 60.9997C55.4867 62.0252 55.9387 63.4502 55.9387 64.5952C55.9387 65.6382 56.1777 66.6397 56.4702 66.8202C56.7627 67.0007 57.4377 66.7542 57.9702 66.2722C58.6672 65.6417 58.9387 64.7352 58.9387 63.0412Z"/>
            <path fill="#808080" fill-rule="evenodd" clip-rule="evenodd" d="M77.9652 28.1052C77.0172 28.6832 75.5922 29.8512 74.7977 30.7017C74.0037 31.5517 72.6662 33.6092 71.8257 35.2742C70.9852 36.9387 70.0682 38.9757 69.7882 39.8007C69.5082 40.6257 69.0962 43.4382 68.8722 46.0507C68.6487 48.6632 68.4037 51.7007 68.3272 52.8007L68.1887 54.8007L70.1887 54.9467C71.9237 55.0732 72.4842 54.8247 74.4187 53.0717C76.4672 51.2152 76.8772 51.0507 79.4552 51.0507C82.1327 51.0507 82.3847 51.1657 84.9387 53.5507C86.7757 55.2662 88.0372 56.0507 88.9592 56.0507C89.7437 56.0507 90.8502 55.5027 91.6207 54.7327C92.6102 53.7432 92.9387 52.9103 92.9387 51.3933C92.9387 50.2813 92.7332 48.6807 92.4822 47.8362C92.2307 46.9917 91.5367 44.8382 90.9397 43.0507C90.3432 41.2632 89.5182 38.9007 89.1072 37.8007C88.6957 36.7007 87.5812 34.3187 86.6297 32.5072C85.3602 30.0887 84.3887 28.9257 82.9762 28.1322C81.9182 27.5372 80.7457 27.0517 80.3707 27.0527C79.9957 27.0537 78.9132 27.5272 77.9652 28.1052ZM81.2537 43.9257C81.0882 43.3072 80.9492 42.0692 80.9457 41.1757C80.9417 40.2822 80.7272 39.5507 80.4682 39.5507C80.2092 39.5507 79.8177 39.8322 79.5972 40.1757C79.3772 40.5197 78.9262 41.5732 78.5952 42.5177C78.2642 43.4622 78.1067 44.4182 78.2457 44.6427C78.3842 44.8672 79.1857 45.0507 80.0267 45.0507C81.3682 45.0507 81.5182 44.9127 81.2537 43.9257Z"/>
            <path fill="#808080" fill-rule="evenodd" clip-rule="evenodd" d="M77.1882 57.9697C76.3627 58.4732 74.8437 60.3057 73.8132 62.0427C72.7822 63.7792 71.9387 65.4027 71.9387 65.6502C71.9387 65.8977 71.5512 66.8202 71.0772 67.7007C70.6037 68.5807 70.0807 70.9882 69.9162 73.0507C69.7512 75.1132 69.4457 77.1792 69.2372 77.6417C68.9517 78.2742 69.2492 78.6857 70.4342 79.2982C71.7482 79.9782 72.1487 80.0042 72.8407 79.4572C73.2972 79.0962 74.1707 78.3147 74.7822 77.7212C75.7557 76.7752 76.2842 76.6747 79.0412 76.9092C81.7402 77.1382 82.3542 77.3812 83.3497 78.6137C84.1272 79.5757 84.9722 80.0507 85.9067 80.0507C86.7482 80.0507 87.8262 79.5272 88.6207 78.7327C89.3457 78.0077 89.9387 77.1797 89.9387 76.8932C89.9387 76.6063 89.7342 75.6807 89.4837 74.8362C89.2337 73.9917 88.7717 72.4007 88.4567 71.3007C88.1417 70.2007 87.4662 68.2882 86.9557 67.0507C86.4447 65.8132 85.7752 64.1182 85.4672 63.2847C85.1592 62.4507 84.4082 61.1877 83.7982 60.4777C83.1877 59.7682 81.9012 58.7072 80.9387 58.1207C79.9762 57.5342 79.0762 57.0542 78.9387 57.0542C78.8012 57.0542 78.0132 57.4662 77.1882 57.9697ZM79.7657 71.4377C80.2477 70.9562 80.3832 70.2542 80.1637 69.3787C79.9802 68.6482 79.6377 68.0507 79.4022 68.0507C79.1667 68.0507 78.7412 68.4857 78.4562 69.0177C78.1717 69.5497 77.9387 70.3242 77.9387 70.7382C77.9387 71.1527 78.1852 71.6442 78.4867 71.8302C78.7882 72.0167 79.3637 71.8402 79.7657 71.4377Z"/>
            </svg>
        </Icon>
    )  
}